import Drawer from "@mui/material/Drawer";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Popover } from "@mui/material";
import clsx from "clsx";
import style from "./index.module.scss";

export enum BottomSheetType {
  bottom = "bottom",
  left = "left",
  right = "right",
  center = "center",
  pop = "pop",
}
interface BottomSheetProps {
  drawerState: boolean;
  toggleDrawer?: (key: boolean) => void;
  desktopPosition?: any;
  mobilePosition?: any;
  children: React.ReactNode;
  customPaperStyle?: any;
  anchorEl?: any;
}

export default function BottomSheet({
  drawerState,
  toggleDrawer,
  desktopPosition = BottomSheetType.right,
  mobilePosition = BottomSheetType.left,
  children,
  customPaperStyle = {},
  anchorEl,
}: BottomSheetProps) {
  const isMobile = useMediaQuery("(max-width:768px)");
  const handleClose = () => toggleDrawer?.(false);
  if (
    (!isMobile && desktopPosition === BottomSheetType.center) ||
    (isMobile && mobilePosition === BottomSheetType.center)
  ) {
    return (
      <Dialog
        disableScrollLock
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={drawerState}
        classes={{
          root: customPaperStyle,
        }}
      >
        {children}
      </Dialog>
    );
  }
  if (
    (!isMobile && desktopPosition === BottomSheetType.pop) ||
    (isMobile && mobilePosition === BottomSheetType.pop)
  )
    return (
      <Popover
        disableScrollLock
        onClose={handleClose}
        open={drawerState}
        anchorEl={anchorEl?.anchorElement}
        anchorOrigin={{
          vertical: anchorEl?.vertical,
          horizontal: anchorEl?.horizontal,
        }}
        disableAutoFocus={anchorEl?.persistent}
      >
        {children}
      </Popover>
    );
  return (
    <Drawer
      disableScrollLock={false}
      classes={{
        paper: clsx(customPaperStyle, style.customScroll, style.container),
      }}
      anchor={isMobile ? mobilePosition : desktopPosition}
      open={drawerState}
      onClose={handleClose}
    >
      {children}
    </Drawer>
  );
}
