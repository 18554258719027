import { ButtonType } from '../../types';
import classes from './button.module.scss';

interface GoButtonProps {
  type?: string;
  onClickHandler?: () => void; 
  title?: string;
  icon?: React.ReactNode;
  customContainerStyle?: any;
  isDisabled?: boolean;
}
export default function GoButton({
  type = ButtonType.filled,
  onClickHandler,
  title,
  icon,
  customContainerStyle = {},
  isDisabled,
}: GoButtonProps) {
  return (
    <button
      onClick={onClickHandler}
      type="button"
      style={customContainerStyle}
      className={`${classes.buttonContainer} ${
        isDisabled
          ? classes[`${type}DisabledButtonContainer`]
          : classes[`${type}ButtonContainer`]
      }`}
      disabled={isDisabled}
    >
      {/* eslint-disable-next-line no-constant-condition */}
      {title ? (
        <span className={classes[`${type}ButtonText`]}>{title}</span>
      ) : (
        <span className={classes.buttonIcon}>{icon}</span>
      )}
    </button>
  );
}
