import  { useEffect, useState } from "react";
import AuthLayout from "../auth/auth-layout";
import LoginScreen from "../auth/login-screen";
import EnterOtpScreen from "../auth/otp-screen";
import { fetchGoData } from "../utils/Methods";
export enum AuthState {
  LOGIN_SCREEN = "LOGIN_SCREEN",
  ENTER_OTP_SCREEN = "ENTER_OTP_SCREEN",
}
const verifyToken = (token: string) =>
new Promise((resolve, reject) => {
  const accessToken = fetchGoData("token");
  if (accessToken) resolve({ verified: true });
  reject("No token");
});
const AuthContainer = () => {
  useEffect(() => {
    const checkToken = async () => {
      const accessToken = fetchGoData("token");
      const data: any = await verifyToken(accessToken);
      if (data.verified) {
       window.location.href="/reserve"
      }
    };
    checkToken();
  }, []);
  const [authState, setAuthState] = useState<AuthState>(AuthState.LOGIN_SCREEN);
  const [email, setEmail] = useState("");
  const changeScreen = (screen: AuthState, email: string = "") => {
    setAuthState(screen);
    setEmail(email);
  };

  const ENUM_STATES: any = {
    [AuthState.LOGIN_SCREEN]: <LoginScreen changeScreen={changeScreen} />,
    [AuthState.ENTER_OTP_SCREEN]: (
      <EnterOtpScreen email={email} changeScreen={changeScreen} />
    ),
  };
  return <AuthLayout>{ENUM_STATES[authState]}</AuthLayout>;
};

export default AuthContainer;
