import { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { AuthState } from "../../container/AuthContainer";
import GoOtpInput from "../../components/otp-input";
import { AuthService } from "../../utils/Api";
import ApiEndpoints from "../../utils/ApiEndpoints";
import { setGoData } from "../../utils/Methods";
interface EnterOtpScreenProps {
  changeScreen: (screen: AuthState) => void;
  email: string;
}
export enum SubscriptionStatus {
  TRIAL_ENDING = "TRIAL_ENDING",
  TRIAL_ENDED = "TRIAL_ENDED",
  SUBSCRIPTION_ENDING = "SUBSCRIPTION_ENDING",
  SUBSCRIPTION_ENDED = "SUBSCRIPTION_ENDED",
  SUBSCRIPTION_CANCELLED = "SUBSCRIPTION_CANCELLED",
  TRIAL_CANCELLED = "TRIAL_CANCELLED",
}

export default function EnterOtpScreen({
  changeScreen,
  email,
}: EnterOtpScreenProps) {
  const [otp, setOtp] = useState("");
  const [count, setCount] = useState(60);
  const [errorMsg, seterrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");

  const handleResendOtp = () => {
    setOtp("");
    resendOtp();
  };
  useEffect(() => {
    if (otp?.toString()?.length === 6) {
      verifyOtp();
    }
  }, [otp]);
  const verifyOtp = () => {
    AuthService.post(ApiEndpoints.auth.verifyOtp, {
      email: email,
      otp: otp,
    })
      .then(({ status, data }) => {
        if (status === 200) {
          const { code, account, accessToken, countrySettings } = data;
          if (
            code === "SUCCESS" ||
            code === SubscriptionStatus.SUBSCRIPTION_ENDING ||
            code === SubscriptionStatus.TRIAL_ENDING
          ) {
            localStorage.setItem("token", accessToken);
            setGoData("account", account);
            setGoData("countrySettings", countrySettings);
            if (account?.outlets?.length === 1) {
              const { _id: id, brandRef } = account?.outlets?.[0] || {};
              setGoData("selectedOutlet", {
                id,
                brandLogo: brandRef?.logo,
                brandColor: brandRef?.brandColor || "#111111",
                secondaryBrandColor: brandRef?.secondaryBrandColor || "#ffac33",
              });
              window.location.href = "/reserve";
            } else {
              window.location.href = "/outlet";
            }
          } else {
            seterrorMsg(data?.message);
          }
        } else {
          seterrorMsg("issue! Please try again");
        }
      })
      .catch(({ response, message }: { response: any; message: string }) => {
        seterrorMsg(response?.data?.message || message);
      });
  };
  const resendOtp = () => {
    AuthService.post(ApiEndpoints.auth.resendOtp, { email: email })
      .then(({ status, data }) => {
        setCount(60);
        seterrorMsg("");
        setsuccessMsg(data.message);
      })
      .catch((err) => {
        seterrorMsg(err.message);
      });
  };
  const handleBacktoPreviousState = () => {
    changeScreen(AuthState.LOGIN_SCREEN);
  };
  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (count > 0) {
        setCount(count - 1);
      }
    }, 1000);
    return () => {
      clearInterval(timerInterval);
    };
  }, [count]);
  return (
    <div className={styles.otpMain}>
      <div className={styles.verifyImg}>{/* <Verify /> */}</div>
      <div className={styles.heading}>Please verify email account</div>
      <div className={styles.subHeading}>
        {` An OTP was sent to `}
        <span className={styles.emailHeading}>{email}</span>
      </div>
      <div className={styles.otpBox}>
        <GoOtpInput handleChange={setOtp} otp={otp} />
      </div>
      {errorMsg ? <div className={styles.errorText}>{errorMsg}</div> : null}
      {successMsg ? (
        <div className={styles.successText}>{successMsg}</div>
      ) : null}

      <div className={styles.timerContainer}>
        00:{count.toString().padStart(2, "0")} Sec
      </div>
      <div className={styles.subHeading}>
        {"Didn't receive an OTP? Click on"}
        {count > 0 ? (
          " Resend"
        ) : (
          <span
            onKeyDown={handleResendOtp}
            role="button"
            tabIndex={-2}
            className={styles.resendOtpActive}
            onClick={handleResendOtp}
          >
            Resend
          </span>
        )}
      </div>
      <div
        onKeyDown={handleBacktoPreviousState}
        role="button"
        tabIndex={0}
        className={styles.backButton}
        onClick={handleBacktoPreviousState}
      >
        Back
      </div>
    </div>
  );
}
