import { ENDPOINTS } from "./Urls";
import axios from "axios";
import { fetchGoData } from "./Methods";

export const AuthService = axios.create({
  baseURL: `${ENDPOINTS.api_endpoint}/accounts`,
  timeout: 10 * 1000,
  headers: {
    "Content-Type": "application/json",
    "x-access-token": fetchGoData("accessToken"),
  },
});
export const BookingService = axios.create({
  baseURL: `${ENDPOINTS.api_endpoint}/bookings`,
  timeout: 10 * 1000,
  headers: {
    "Content-Type": "application/json",
    "x-access-token": fetchGoData("accessToken"),
  },
});
export const CustomerService = axios.create({
  baseURL: `${ENDPOINTS.api_endpoint}/customers`,
  timeout: 10 * 1000,
  headers: {
    "Content-Type": "application/json",
    "x-access-token": fetchGoData("accessToken"),
  },
});
