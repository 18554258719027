export enum ButtonType {
    outlined = "outlined",
    filled = "filled",
    white = "white",
    transparent = "transparent",
    greyed='greyed'
  }
  export enum BookingType {
    "reservation" = 1,
    "waiting" = 2,
    "walkin" = 3,
  }