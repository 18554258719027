import { useState } from "react";
import GoButton from "../../components/button";
import GoInputField from "../../components/input-field";
import { FormKeys, ReservationState } from "../../container/ReservationContainer";
import styles from "./index.module.scss";
interface NameScreenProps {
  changeScreen: (screen: ReservationState, data?: any) => void;
  reservationData: any;
}
const NameScreen: React.FC<NameScreenProps> = ({
  changeScreen,
  reservationData,
}) => {
  const [name, setname] = useState(reservationData[FormKeys.name]);
  const handleClick = () => {
    changeScreen(ReservationState.GUEST_PICKER_SCREEN, {
      key: FormKeys.name,
      value: name,
    });
  };

  const [error, setError] = useState<any>(null);

  const keyPressHandler = (event:any) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      if(name) {
        handleClick()
      }else {
        setError('Please enter your name to proceed further');
      }
    }
  }

  return (
    <div  className={styles.container}>
      <GoInputField
        label="Your name please"
        value={name}
        onChange={setname}
        onKeyPress={keyPressHandler}
        maxLength={30}
        placeholder="First Name and Last Name"
        autofocus={!name}
        errorMsg={error}
      />
      {name ? (
        <div className={styles.buttonContainer}>
          <GoButton onClickHandler={handleClick} title="NEXT" />
        </div>
      ) : null}
    </div>
  );
};

export default NameScreen;
