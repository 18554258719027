import GoButton from "../../components/button";
import {
  FormKeys,
  ReservationState,
} from "../../container/ReservationContainer";
import { ButtonType } from "../../types";
import styles from "./index.module.scss";
import logo from "../../assets/success.png";
import call from "../../assets/call.png";

interface SuccessScreenProps {
  changeScreen: (screen: ReservationState) => void;
  reservationData: any;
  resetData: () => void;
}
const SuccessScreen: React.FC<SuccessScreenProps> = (props) => {
  const { changeScreen, reservationData, resetData } = props;
  const handleWalkin = () => {
    changeScreen(ReservationState.PHONE_SCREEN);
    resetData();
  };
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
      <div>
        <img className={styles.logo} src={logo} />
        <div className={styles.thanksText}>Thank you {reservationData[FormKeys.name]}</div>
      </div>
      <div className={styles.queueText}>
        You are placed at #{reservationData[FormKeys.queue]} in the queue
      </div>
      <div>
        <img className={styles.callImage} src={call} />

        <div style={{ width: "70%", margin: "auto" }}>
          Please wait! We’ll message you once your table is ready.
        </div>
      </div>

      <div className={styles.addButton}>
        <GoButton
          onClickHandler={handleWalkin}
          customContainerStyle={{ width: "70%" }}
          type={ButtonType.greyed}
          title="ADD NEW WALKIN"
        />
      </div>
      </div>
    </div>
  );
};
export default SuccessScreen;
