import OtpInput from "react-otp-input";

interface GoOtpInputProps {
  handleChange: (val: string) => void;
  numInputs?: number | undefined;
  separator?: any;
  otp: string;
}
const inputStyle = {
  width: "calc(100% - 10px)",
  flexShrink: 0,
  height: "120px",
  background: "#FFFFFF",
  border: "1px solid rgba(0, 0, 0, 0.05)",
  boxShadow: "0px 4px 4px rgba(216, 216, 216, 0.25)",
  borderRadius: 10,
  fontSize:"28px"
};

export default function GoOtpInput({
  handleChange,
  numInputs = 6,
  otp,
}: GoOtpInputProps) {
  return (
    <div>
      <OtpInput
        value={otp}
        onChange={handleChange}
        numInputs={numInputs}
        // separator={separator}
        inputStyle={inputStyle}
        shouldAutoFocus
        isInputNum
      />
    </div>
  );
}
