export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  value === "null" ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);
const IsJsonString = (str) => {
  try {
   const json = JSON.parse(str);
    return typeof json === "object";
  } catch (e) {
    return false;
  }
};

export const setGoData = (key, value) => {
  localStorage.setItem(
    key,
    !IsJsonString(value) ? JSON.stringify(value) : value
  );
};
export const fetchGoData = (key) => {
  const data = localStorage.getItem(key);
  if (IsJsonString(data)) return JSON.parse(data);
  return data;
};

export const removeGoData = (key) => {
  localStorage.removeItem(key);
};
