import styles from "./index.module.scss";
import backArrow from "../../assets/back.svg";

const Header = ({
  filled = 0,
  hasBackButton,
  handleBack
}: {
  filled: number;
  hasBackButton?: boolean;
  handleBack?:()=>void
}) => {
  return (
    <div className={styles.container}>
      {hasBackButton ? (
        <div className={styles.backArrowContainer}>
          <img
            onClick={handleBack}
            className={styles.backArrow}
            src={backArrow}
            alt="logo"
          />
        </div>
      ) : null}
      <div style={{width:"100%"}}>
        <div className={styles.row}>
          <div className={styles.textStyle}>Progress Status</div>
          <div className={styles.textStyle}>{filled}%</div>
        </div>
        <div className={styles.progressBar}>
          <div
            className={styles.filledProgressBar}
            style={{ width: `${filled}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};
export default Header;
