import { useState } from "react";
import GoButton from "../../components/button";
import GoInputField from "../../components/input-field";
import BottomSheet, { BottomSheetType } from "../../components/popup";
import {
  FormKeys,
  ReservationState,
} from "../../container/ReservationContainer";
import { BookingType } from "../../types";
import { BookingService } from "../../utils/Api";
import ApiEndpoints from "../../utils/ApiEndpoints";
import { fetchGoData } from "../../utils/Methods";
import styles from "./index.module.scss";

interface PreferencesScreenProps {
  changeScreen: (screen: ReservationState, data?: any) => void;
  reservationData: any;
}
const PreferencesScreen: React.FC<PreferencesScreenProps> = ({
  changeScreen,
  reservationData,
}) => {
  const [request, setrequest] = useState("");
  const [selectedpreferences, setselectedpreferences] = useState<any[]>([]);
  const [errorAlert, setErrorAlert] = useState("");
  const handleWalkin=()=>{
    changeScreen(ReservationState.MAIN_SCREEN)
  }
  const handleClick = () => {
    (BookingService as any).defaults.headers["x-access-token"] =
      fetchGoData("token");
    const selectedOutlet = fetchGoData("selectedOutlet");

    BookingService.post(ApiEndpoints.reservations.createReservation, {
      outletId: selectedOutlet?.id,
      ...reservationData,
      [FormKeys.preference]: selectedpreferences,
      [FormKeys.guestComments]: request,
      [FormKeys.bookingType]: BookingType.waiting,
      [FormKeys.status]: 2,
      [FormKeys.source]: 7,
      sourceType: 1, //Web -1
      isGuest: 1,
    })
      .then(({ data }) => {
        if (data.code === "SUCCESS") {
          changeScreen(ReservationState.SUCCESS_SCREEN, {
            key: FormKeys.queue,
            value: data?.queue,
          });
        } else {
          setErrorAlert(data?.message || "Something went wrong!!");
        }
      })
      .catch((err) => {
        console.log({ err });
        return err;
      });
  };
  const preferences = [
    {
      name: "Smoking Area",
      icon: "https://reservegoappicons.s3.ap-south-1.amazonaws.com/icons/preferance/Smoke.svg",
      enabled: 1,
    },
    {
      name: "Kids Friendly",
      icon: "https://reservegoappicons.s3.ap-south-1.amazonaws.com/icons/preferance/Kids.svg",
      enabled: 1,
    },
    {
      name: "Low Music",
      icon: "https://reservegoappicons.s3.ap-south-1.amazonaws.com/icons/preferance/Music.svg",
      enabled: 1,
    },
     {
      name: "Pet Friendly",
      icon: "https://reservegoappicons.s3.ap-south-1.amazonaws.com/icons/VectorpetFriendly.svg",
      enabled: 1,
    },
  ];
  const userDetails = [
    {
      title: "Guest Name",
      key: FormKeys.name,
    },
    {
      title: "Guest Count",
      key: FormKeys.noOfGuests,
    },
    {
      title: "Phone Number",
      key: FormKeys.phone,
    },
    {
      title: "Email ID",
      key: FormKeys.email,
    },
  ];
  const handlePrefChange = (data: any) => {
    const updPreferences: any[] = [...selectedpreferences];
    const index = selectedpreferences.findIndex(
      (pref: any) => pref.name === data.name
    );
    if (index === -1) updPreferences.push(data);
    else updPreferences.splice(index, 1);
    setselectedpreferences(updPreferences);
  };

  const keyPressHandler = (event:any) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      handleClick()
    }
  }
  return (
    <>
      <BottomSheet
        drawerState={!!errorAlert}
        desktopPosition={BottomSheetType.center}
        mobilePosition={BottomSheetType.center}
      >
        <div className={styles.alertContainer}>
          {errorAlert}
          <GoButton
            customContainerStyle={{
              position: "absolute",
              bottom: 0,
              left: 0
            }}
            onClickHandler={handleWalkin}
            title="Add new Walkin"
          />
        </div>
      </BottomSheet>
      <div className={styles.container}>
        <div  className={styles.topContainer} style={{ paddingBottom: 10}}>
          <div className={styles.labelText}>
            Would you like to choose any preference?
          </div>
          <div className={styles.prefContainer}>
            {preferences?.map((item) => (
              <div
                onClick={() => handlePrefChange(item)}
                className={
                  selectedpreferences?.findIndex(
                    (pref) => pref.name === item.name
                  ) !== -1
                    ? styles.selectedPreference
                    : styles.unSelectedPreference
                }
                key={item.name}
              >
                <img className={styles.prefIcon} alt="icon" src={item.icon} />
                <div style={{fontSize: '12px'}}>{item.name}</div>
              </div>
            ))}
          </div>
          <GoInputField
            value={request}
            onChange={setrequest}
            label="Any Special Request?"
            placeholder="Enter If any"
            onKeyPress={keyPressHandler}
          />
        </div>
        <div className={styles.lowerContainer}>
          {userDetails?.map(({ title, key }) => (
            <div className={styles.userDetailsContainer}>
              <div className={styles.userDetailsTitle}>{title}</div>
              <div className={styles.userDetailsDesc}>
                {reservationData?.[key] || "-"}
              </div>
            </div>
          ))}
        </div>
        <div className={styles.buttonContainer}>
          <GoButton onClickHandler={handleClick} title="SUBMIT" />
        </div>
      </div>
    </>
  );
};

export default PreferencesScreen;
