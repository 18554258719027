import "./App.css";
import { Routes, Route } from "react-router-dom";
import ReservationScreen from "./container/ReservationContainer";
import AppLayout from "./layout/AppLayout";
import AuthContainer from "./container/AuthContainer";
import SelectOutletScreen from "./auth/select-outlet";
import { useEffect } from "react";
import { fetchGoData } from "./utils/Methods";

function App() {
  useEffect(() => {
    const outlet = fetchGoData("selectedOutlet");
    const root = document.documentElement;
    root?.style.setProperty("--primary-color", outlet?.brandColor || "#111111");
    root?.style.setProperty(
      "--secondary-color",
      outlet?.secondaryBrandColor || "#ffac33"
    );
  }, []);
  return (
    <div className="App">
      <AppLayout>
        <Routes>
          <Route path="/" element={<AuthContainer />} />
          <Route path="/outlet" element={<SelectOutletScreen />} />
          <Route path="/reserve" element={<ReservationScreen />} />
        </Routes>
      </AppLayout>
    </div>
  );
}

export default App;
