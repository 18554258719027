import GoButton from "../../components/button";
import { ReservationState } from "../../container/ReservationContainer";
import styles from "./index.module.scss";
import logo from "../../assets/icon.png";
import name from "../../assets/reservego.png";
import logout from "../../assets/logout.png";
import { fetchGoData } from "../../utils/Methods";

interface MainScreenProps {
  changeScreen: (screen: ReservationState) => void;
  resetData:()=>void;
}
const MainScreen: React.FC<MainScreenProps> = (props) => {
  const { changeScreen,resetData } = props;
  const selectedOutlet = fetchGoData("selectedOutlet");
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };
  const handleAddWalkin=()=>{
    changeScreen(ReservationState.PHONE_SCREEN)
    resetData()
  }
  return (
    <div className={styles.container}>
      <div className={styles.endContainer}>
        <img
          onClick={handleLogout}
          className={styles.logout}
          src={logout}
          alt="logout"
        />
        <div className={styles.mainOuter}>
        <div className={styles.welcomeText}>Welcome to</div>
        {selectedOutlet?.brandLogo ? (
          <img className={styles.outletLogo} src={selectedOutlet?.brandLogo} alt="logo" />
        ) : (
          <div className={styles.mainLogo}>
            <div><img className={styles.logo} src={logo} alt="logo" /></div>
            <div><img className={styles.name} src={name} alt="name" /></div>
          </div>
        )}

          <div className={styles.walkinButton}>
            <GoButton
              onClickHandler={handleAddWalkin}
              customContainerStyle={{ width: "70%" }}
              title="ADD WALKIN"
            />
          </div>
        </div>
        
        
        <div className={styles.footerText}>Powered by <span><a href="https://reservego.co/" target={'_blank'}>ReserveGo</a></span></div>
      </div>
    </div>
  );
};
export default MainScreen;
