import styles from "./index.module.scss";
import logo from "../../assets/logo.png";
interface AuthLayoutProps {
  children: React.ReactNode;
}

export default function AuthLayout({ children }: AuthLayoutProps) {
  return (
      <div className={styles.authContainer}>
          <img className={styles.header} src={logo}  alt="logo"/>

        <div className={styles.container}>{children}</div>
      </div>
  );
}
