import { useEffect, useState } from "react";
import { fetchGoData } from "../utils/Methods";

const verifyToken = (token: string) =>
  new Promise((resolve, reject) => {
    const accessToken = fetchGoData("token");
    if (accessToken) resolve({ verified: true });
    reject("No token");
  });

const withAuth = (WrappedComponent: any) => {
  return (props: any) => {
    const [verified, setVerified] = useState(false);

    useEffect(() => {
      const checkToken = async () => {
        const accessToken = fetchGoData("token");
        // if no accessToken was found,then we redirect to "/login" page.
        if (!accessToken) {
          window.location.href="/";
        } else {
          // we call the api that verifies the token.
          const data: any = await verifyToken(accessToken);
          const selectedOutlet = fetchGoData("selectedOutlet");
          if(!selectedOutlet?.id){
            window.location.href="/outlet";
          }
          // if token was verified we set the state.
          if (data.verified) {
            setVerified(data.verified);
          }
        }
      };
      checkToken();
    }, []);

    if (verified) {
      return <WrappedComponent {...props} />;
    } else {
      return null;
    }
  };
};

export default withAuth;
