import { useState } from "react";
import withAuth from "../auth/withAuth";
import GuestPickerScreen from "../reserve/guest-screen";
import MainScreen from "../reserve/main-screen";
import NameScreen from "../reserve/name-screen";
import PhoneScreen from "../reserve/phone-screen";
import PreferencesScreen from "../reserve/preferences-screen";
import ReserveLayout from "../reserve/reserve-layout";
import SuccessScreen from "../reserve/success-screen";
export enum ReservationState {
  MAIN_SCREEN = "MAIN_SCREEN",
  PHONE_SCREEN = "PHONE_SCREEN",
  NAME_SCREEN = "NAME_SCREEN",
  GUEST_PICKER_SCREEN = "GUEST_PICKER_SCREEN",
  PREFERENCES_SCREEN = "PREFERENCES_SCREEN",
  SUCCESS_SCREEN = "SUCCESS_SCREEN",
}
export enum FormKeys {
  bookingType = "bookingType",
  phone = "guestPhone",
  name = "guestName",
  email = "guestEmail",
  bookingTime = "bookingTime",
  noOfGuests = "guestCount",
  guestComments = "guestComments",
  bday = "bday",
  anniv = "anniv",
  gender = "gender",
  sectionIds = "sectionIds",
  sectionNames = "sectionNames",
  tableIds = "tableIds",
  tableNames = "tableNames",
  preference = "preference",
  source = "source",
  tags = "tags",
  status = "status",
  priority = "priority",
  queue = "queue",
}
const ReservationScreen = () => {
  const [authState, setAuthState] = useState<ReservationState>(
    ReservationState.MAIN_SCREEN
  );
  const [isBack, setIsBack] = useState(false);
  const [reservationData, setReservationData] = useState<any>({});
  const [reservationSettings, setReservationSettings] = useState<any>({});

  const setData = (key: string, value: any) => {
    let tempData: any = { ...reservationData };
    tempData[key] = value;
    setReservationData(tempData);
  };
  const changeScreen = (
    screen: ReservationState,
    data: any = {},
    isBack?: boolean
  ) => {
    if (isBack) {
      setIsBack(true);
    } else setIsBack(false);
    const { key, value } = data;
    setAuthState(screen);
    if (key && value) setData(key, value);
  };
  const resetData = () => {
    setReservationData({});
  };
  const ENUM_STATES: any = {
    [ReservationState.MAIN_SCREEN]: (
      <MainScreen resetData={resetData} changeScreen={changeScreen} />
    ),
    [ReservationState.PHONE_SCREEN]: (
      <PhoneScreen
        reservationData={reservationData}
        isBack={isBack}
        changeScreen={changeScreen}
        setReservationData={setReservationData}
      />
    ),
    [ReservationState.NAME_SCREEN]: (
      <NameScreen
        reservationData={reservationData}
        changeScreen={changeScreen}
      />
    ),
    [ReservationState.GUEST_PICKER_SCREEN]: (
      <GuestPickerScreen
        reservationData={reservationData}
        changeScreen={changeScreen}
      />
    ),
    [ReservationState.PREFERENCES_SCREEN]: (
      <PreferencesScreen
        reservationData={reservationData}
        changeScreen={changeScreen}
      />
    ),
    [ReservationState.SUCCESS_SCREEN]: (
      <SuccessScreen
        reservationData={reservationData}
        changeScreen={changeScreen}
        resetData={resetData}
      />
    ),
  };
  const getFilledWidth = () => {
    let num = 0;
    if (authState === ReservationState.NAME_SCREEN) num = 30;
    else if (authState === ReservationState.GUEST_PICKER_SCREEN) num = 60;
    else if (authState === ReservationState.PREFERENCES_SCREEN) num = 90;

    return num;
  };
  const handleBack = () => {
    if (authState === ReservationState.NAME_SCREEN)
      changeScreen(ReservationState.PHONE_SCREEN, {}, true);
    else if (authState === ReservationState.GUEST_PICKER_SCREEN)
      changeScreen(ReservationState.NAME_SCREEN, {}, true);
    else if (authState === ReservationState.PREFERENCES_SCREEN)
      changeScreen(ReservationState.GUEST_PICKER_SCREEN, {}, true);
  };
  return (
    <ReserveLayout
      hasStatusBar={
        authState !== ReservationState.MAIN_SCREEN &&
        authState !== ReservationState.SUCCESS_SCREEN
      }
      hasBackButton={authState !== ReservationState.PHONE_SCREEN}
      filled={getFilledWidth()}
      handleBack={handleBack}
    >
      {ENUM_STATES[authState]}
    </ReserveLayout>
  );
};

export default withAuth(ReservationScreen);
