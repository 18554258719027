import clsx from "clsx";
import styles from "./index.module.scss";
import backArrow from "../../assets/back.svg";

interface GoGuestPickerProps {
  limit?: number;
  selected?: number | null;
  onSelectNumber: (guest: number) => void;
  error?: string;
  label?: string;
  onClick?: any;
}
const GoGuestPicker = ({
  limit = 10,
  selected,
  onSelectNumber,
  error,
  label,
  onClick
}: GoGuestPickerProps) => {

  const keyPressHandler = (event:any) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      if(selected) {
        onClick()
      }
    }
  }
  return (
    <>
      <div style={{width: '100%'}}>
        <div className={styles.labelContainer}>
          {label && <span className={styles.labelText}>{label}</span>}
        </div>
        <div className={styles.container}>
          {[...Array(limit)].map((_, index) => (
            <div
              onClick={() => onSelectNumber(index + 1)}
              className={
                selected === index + 1
                  ? clsx(styles.guestPicker, styles.selected)
                  : styles.guestPicker
              }
              key={index}
            >
              {index + 1}
            </div>
          ))}
        </div>

        {error ? <div className={styles.errorMessage}>{error}</div> : null}
      </div>
      <div className={styles.guestInputContainer}>
        <span className={styles.extraGuestLabel}>Or enter the guest count</span>
        <input
          max="999"
          maxLength={3}
          type="tel"
          onChange={(ev) => {
            onSelectNumber(Number(ev.target.value));
          }}
          className={styles.guestInput}
          placeholder={`${limit}+`}
          onKeyUp={keyPressHandler}
        />
      </div>
    </>
  );
};
export default GoGuestPicker;
