import Header from "../../components/header";
import styles from "./index.module.scss";
interface ReserveLayoutProps {
  children: React.ReactNode;
  filled: number;
  hasStatusBar?: boolean;
  hasBackButton?: boolean;
  handleBack?:()=>void
}

export default function ReserveLayout({
  children,
  filled,
  hasStatusBar,
  hasBackButton,
  handleBack
}: ReserveLayoutProps) {
  return (
    <div className={styles.reserveContainer}>
      {hasStatusBar ? <Header handleBack={handleBack} hasBackButton={hasBackButton} filled={filled} /> : null}
      <div className={hasStatusBar ? styles.container : styles.fullContainer}>
        {children}
      </div>
    </div>
  );
}
