import { fetchGoData, setGoData } from "../../utils/Methods";
import AuthLayout from "../auth-layout";
import styles from "./index.module.scss";

const SelectOutletScreen = () => {
  const account = fetchGoData("account");
  const handleOutletSelection = (item: any) => {
    const { _id: id, brandRef } = item;
    setGoData("selectedOutlet", {
      id,
      brandLogo: brandRef?.logo,
      brandColor: brandRef?.brandColor || "#ffac33",
    });
    window.location.href = "/reserve";
  };
  return (
    <AuthLayout>
      <div className={styles.container}>
        <div className={styles.heading}>Select Outlet</div>
        {account?.outlets?.map((item: any) => {
          const { name, city } = item;
          return (
            <div
              onClick={() => handleOutletSelection(item)}
              className={styles.outletCard}
              key={name}
            >
              {name}
              {city ? `-${city}` : ""}
            </div>
          );
        })}
      </div>
    </AuthLayout>
  );
};
export default SelectOutletScreen;
